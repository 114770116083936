// extracted by mini-css-extract-plugin
export var article = "blog-module--article--7ea7b";
export var articleTitle = "blog-module--article-title--75525";
export var articles = "blog-module--articles--b496b";
export var bio = "blog-module--bio--b0f79";
export var bioWrapper = "blog-module--bio-wrapper--e3f86";
export var date = "blog-module--date--8988c";
export var imgWrapper = "blog-module--img-wrapper--ea69d";
export var siteTitle = "blog-module--site-title--10494";
export var socialWrapper = "blog-module--social-wrapper--6028c";
export var tag = "blog-module--tag--4a981";
export var tags = "blog-module--tags--57a84";
export var textWrapper = "blog-module--text-wrapper--4da65";